import { useQuery } from "react-query";
import { fetchOrders, fetchOrderByBarcode } from "../api/order";

type OrderSearchParams = {
  bucket?: string;
  barcode?: string;
  customerEmail?: string;
  shopifyId?: string;
  certNumber?: string;
};

export const useFetchOrders = (
  offset: number,
  limit: number,
  order: AscendingOrder,
  orderBy?: string,
  searchParams?: OrderSearchParams
) =>
  useQuery(
    [
      "orders",
      {
        offset,
        limit,
        order,
        orderBy,
        ...searchParams
      }
    ],
    () =>
      fetchOrders({
        offset,
        limit,
        order,
        orderBy,
        ...searchParams
      }),
    {
      keepPreviousData: true
    }
  );

export const useFetchOrderByBarcode = (barcode?: string) =>
  useQuery(["order", { barcode }], () => fetchOrderByBarcode(barcode!), {
    enabled: barcode !== undefined
  });
