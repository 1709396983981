import { extractGradingData } from "./gradingHelpers";
import {
  getBackLine5Text,
  getBackLine3Text,
  getFinishAndRarity
} from "./labelHelpers";
import downloadFile from "js-file-download";

export type PythonLabel = {
  cardName: string;
  setName: string;
  gameName: string;
  overallGrade: number | string;
  centeringGrade: number,
  cornersGrade: number,
  edgesGrade: number,
  surfaceGrade: number,
  finishAndRarity: string,
  yearLanguageAndCardNumber: string,
  certNumber: string
};

export const orderItemToPythonLabel = (orderItem : Grading) => {
  if(!orderItem.grade) return null;

  const gradingData = extractGradingData(orderItem);

  return {
      cardName: orderItem.cardName,
      setName: getBackLine3Text(gradingData),
      gameName: orderItem.game,
      overallGrade: (
        (orderItem.finalGrade === 10 && orderItem.grade === "Gem Mint") ?
        "GEM MINT 10" : orderItem.finalGrade
      ),
      centeringGrade: orderItem.centeringGrade,
      cornersGrade: orderItem.cornersGrade,
      edgesGrade: orderItem.edgesGrade,
      surfaceGrade: orderItem.surfaceGrade,
      finishAndRarity: getFinishAndRarity(gradingData),
      yearLanguageAndCardNumber: getBackLine5Text(gradingData),
      certNumber: orderItem.gradedId
  }
};

export const downloadPythonLabels = (id : number | string, pythonLabels : PythonLabel[]) => {
  const filename = `${id} Label.json`;
  const data = JSON.stringify(pythonLabels, null, 4);
  downloadFile(data, filename);
};
