import { useContext } from "react";
import { AlertContext } from "../providers/AlertProvider";

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert hook must be used with AlertProvider");
  }
  const { addAlert, removeAlert } = context;
  return { addAlert, removeAlert };
}