import React, { useEffect, useState } from "react";
import { useFetchGameInfo, useFetchSetInfo } from "../../hooks/cardHooks";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import CardDetails from "./CardDetails";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles({
  paper: {
    padding: "1rem"
  },
  input: {
    marginBottom: "1rem"
  }
});

interface CardSelectionProps {
  selectedCard?: SearchCard;
  setSelectedCard: React.Dispatch<React.SetStateAction<SearchCard | undefined>>;
  game: string;
  setGame: React.Dispatch<React.SetStateAction<string>>;
  setName: string;
  setSetName: React.Dispatch<React.SetStateAction<string>>;
  cardName: string;
  setCardName: React.Dispatch<React.SetStateAction<string>>;
  cardNumber: string;
  setCardNumber: React.Dispatch<React.SetStateAction<string>>;
  finish: string;
  setFinish: React.Dispatch<React.SetStateAction<string>>;
  cardValue: string;
  setCardValue: React.Dispatch<React.SetStateAction<string>>;
  year: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  rarity: string;
  setRarity: React.Dispatch<React.SetStateAction<string>>;
  cardError: string;
  setCardError: React.Dispatch<React.SetStateAction<string>>;
  cardId: number | undefined;
  setCardId: React.Dispatch<React.SetStateAction<number | undefined>>;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

type SetData = {
  id: number;
  setName: string;
};

function CardSelection(props: CardSelectionProps) {
  const {
    selectedCard,
    setSelectedCard,
    game,
    setGame,
    setName,
    setSetName,
    cardName,
    setCardName,
    cardNumber,
    setCardNumber,
    finish,
    setFinish,
    cardValue,
    setCardValue,
    year,
    setYear,
    rarity,
    setRarity,
    cardError,
    setCardError,
    cardId,
    language,
    setLanguage
  } = props;
  const classes = useStyles();
  const [filterGame, setFilterGame] = useState<string>(
    selectedCard?.gameCode || ""
  );
  const [filterSet, setFilterSet] = useState<SetData | null>();
  const [setsReady, setSetsReady] = useState(false);
  const { isLoading: isLoadingGames, data: gameInfo } = useFetchGameInfo();
  const { isLoading: isLoadingCards, data: cardList } = useFetchSetInfo(
    filterGame,
    filterSet?.setName ?? ""
  );

  useEffect(() => {
    if (gameInfo && !filterSet) {
      if (selectedCard?.setName) {
        const gameSets = gameInfo.find(
          gameOption => gameOption.gameCode === selectedCard?.gameCode
        )?.sets;
        if (!gameSets) return;
        const set = gameSets.find(
          setOption => setOption.setName === selectedCard?.setName
        );
        if (set) {
          setFilterSet(set);
        }
      }
      setSetsReady(true);
    }
  }, [filterSet, gameInfo, selectedCard]);

  const updateFieldsFromCard = (card?: SearchCard) => {
    if (card && card?.id === cardId) {
      return;
    }
    if (card?.gameCode) {
      const selectedGame = gameInfo?.find(
        gameOption => gameOption.gameCode === card.gameCode
      );
      if (selectedGame) {
        setGame(selectedGame?.game);
      }
    }
    if (card?.setName) {
      setSetName(card.setName);
    }
    if (card?.cardName) {
      setCardName(card.cardName);
    }
    if (card?.number) {
      setCardNumber(card.number);
    }
    if (card?.rarity) {
      setRarity(card.rarity);
    }
  };

  useEffect(() => {
    updateFieldsFromCard(selectedCard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGame, filterSet, selectedCard]);

  const handleGameFilterChange = (selectedGame: string) => {
    setFilterGame(selectedGame);
    setFilterSet(undefined);
    setSetsReady(true);
  };

  if (isLoadingGames) return null;

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <FormControl>
          <InputLabel id="select-game">Select Game</InputLabel>
          <Select
            labelId="select-game"
            id="select-game"
            value={filterGame}
            label="Select Game"
            onChange={event =>
              handleGameFilterChange(String(event.target.value))
            }
            disabled={isLoadingGames}
            className={classes.input}
          >
            {gameInfo?.map(gameOption => (
              <MenuItem key={gameOption.gameCode} value={gameOption.gameCode}>
                {gameOption.game}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {setsReady && filterGame && filterGame.length > 0 ? (
          <Autocomplete
            id="select-set"
            defaultValue={filterSet}
            options={
              (gameInfo || ([] as GameInfo[])).find(
                gameOption => gameOption.gameCode === filterGame
              )?.sets || []
            }
            getOptionLabel={option => option.setName}
            getOptionSelected={(option, value) =>
              option.setName === value.setName
            }
            onChange={(event, newValue) => setFilterSet(newValue)}
            disabled={isLoadingGames}
            renderInput={params => (
              <TextField {...params} label="Select Set name" />
            )}
            className={classes.input}
          />
        ) : null}
        {filterSet ? (
          <Autocomplete
            id="select-card"
            defaultValue={selectedCard}
            options={cardList || []}
            getOptionLabel={card => card.title}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, newValue) =>
              setSelectedCard((newValue as unknown) as SetCard)
            }
            disabled={isLoadingCards}
            renderInput={params => (
              <TextField {...params} label="Select Card" />
            )}
            className={classes.input}
          />
        ) : null}
      </Paper>
      {selectedCard?.id ? (
        <CardDetails
          card={selectedCard}
          game={game}
          setGame={setGame}
          setName={setName}
          setSetName={setSetName}
          cardName={cardName}
          setCardName={setCardName}
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          finish={finish}
          setFinish={setFinish}
          cardValue={cardValue}
          setCardValue={setCardValue}
          year={year}
          setYear={setYear}
          rarity={rarity}
          setRarity={setRarity}
          cardError={cardError}
          setCardError={setCardError}
          language={language}
          setLanguage={setLanguage}
        />
      ) : null}
    </>
  );
}

export default CardSelection;
