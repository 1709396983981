import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {
  useFetchRoles,
  useCreateUser,
  useUpdateUser,
} from "../../hooks/userHooks";
import { useAlert } from "../../hooks/alertHooks";
import { titleCase } from "../../helpers/formattingHelpers";
import ProgressButton from "../generic/ProgressButton";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "1rem",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  })
);

const getInputChangeHandler =
  (setFunction: React.Dispatch<React.SetStateAction<string | undefined>>) =>
  (event: React.ChangeEvent<HTMLInputElement>) =>
    setFunction(event.target.value);

const emptyUser = {
  enabled: true,
  name: "",
  email: "",
  role: "",
  buckets: ["Receiving/Research And ID",],
};

interface UserFormProps {
  user?: User;
  ActionsWrapper: Function;
  closeForm: VoidFunction;
}

function UserForm(props: UserFormProps) {
  const { user, ActionsWrapper, closeForm } = props;
  const [name, setName] = useState<OptionalString>("");
  const [email, setEmail] = useState<OptionalString>("");
  const [role, setRole] = useState<OptionalString>("");
  const [errors, setErrors] = useState<string[]>([]);
  const classes = useStyles();
  const { addAlert } = useAlert();

  const { isLoading, data: roles } = useFetchRoles();
  const submitAction = user ? useUpdateUser : useCreateUser;
  const { mutateAsync, isMutating } = submitAction();

  useEffect(() => {
    const userDetails = user ?? emptyUser;
    setName(userDetails.name);
    setEmail(userDetails.email);
    setRole(userDetails.role);
  }, [user]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formErrors = [];
    if (!name || name === "") {
      formErrors.push("name");
    }
    if (!email || email === "") {
      formErrors.push("email");
    }
    if (!role || role === "") {
      formErrors.push("role");
    }
    if (formErrors.length > 0) {
      setErrors(formErrors);
    } else {
      const userDetails = user ?? emptyUser;
      await mutateAsync({
        ...userDetails,
        name: name!,
        email: email!,
        role: role!,
      });
      addAlert("User updated", "success");
      closeForm();
    }
    return false;
  };

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        required
        id="name"
        label="Name"
        value={name}
        onChange={getInputChangeHandler(setName)}
        error={errors.includes("name")}
      />
      <TextField
        required
        id="email"
        label="email"
        type="email"
        value={email}
        onChange={getInputChangeHandler(setEmail)}
        error={errors.includes("email")}
      />
      <TextField
        id="role"
        select
        label="Role"
        value={role}
        onChange={getInputChangeHandler(setRole)}
        error={errors.includes("role")}
        helperText="Please select a role"
      >
        {isLoading ? <CircularProgress size={24} /> : null}
        {roles
          ? roles.map((availableRole) => (
              <MenuItem key={availableRole.id} value={availableRole.name}>
                {titleCase(availableRole.name)}
              </MenuItem>
            ))
          : null}
      </TextField>
      <ActionsWrapper>
        <ProgressButton
          type="submit"
          inProgress={isMutating}
          disabled={isMutating}
          color="primary"
          variant="contained"
        >
          Save
        </ProgressButton>
        <Button color="default" variant="contained" onClick={() => closeForm()}>
          Cancel
        </Button>
      </ActionsWrapper>
    </form>
  );
}

export default UserForm;
