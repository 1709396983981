import { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Action, ActionType } from "../../reducers/orderReducer";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center"
  },
  search: {
    paddingLeft: "0.5rem"
  }
});

type Placeholders = {
  [key in ActionType]: string;
};

const placeholders = {
  [ActionType.SEARCH_BARCODE]: "000000000000",
  [ActionType.SEARCH_ORDER]: "1234",
  [ActionType.SEARCH_EMAIL]: "name@example.com",
  [ActionType.SEARCH_CERT]: "000000123",
  [ActionType.CHANGE_BUCKET]: "",
  [ActionType.CHANGE_PAGE]: ""
} as Placeholders;

const searchFields = [
  { value: ActionType.SEARCH_BARCODE, label: "Barcode" },
  { value: ActionType.SEARCH_ORDER, label: "Order Number" },
  { value: ActionType.SEARCH_CERT, label: "Cert Number" },
  { value: ActionType.SEARCH_EMAIL, label: "Customer Email" }
];

interface OrderSearchProps {
  dispatch: React.Dispatch<Action>;
}

function OrderSearch(props: OrderSearchProps) {
  const { dispatch } = props;
  const [searchField, setSearchField] = useState<ActionType>(
    ActionType.SEARCH_EMAIL
  );
  const searchRef = useRef<HTMLInputElement>();
  const classes = useStyles();

  const handleSearchFieldChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSearchField(event.target.value as ActionType);
  };

  const handleSearch = () => {
    dispatch({
      type: searchField,
      search: searchRef.current?.value as string
    } as Action);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.code === "13") {
      event.preventDefault();
      handleSearch();
      searchRef.current?.blur();
    }
  };

  return (
    <form noValidate autoComplete="off" className={classes.root}>
      <Select
        id="outlined-select-currency"
        value={searchField}
        onChange={handleSearchFieldChange}
      >
        {searchFields.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <TextField
        className={classes.search}
        placeholder={placeholders[searchField]}
        inputRef={searchRef}
        onKeyUp={handleKeyUp}
      />
      <IconButton onClick={() => handleSearch()}>
        <SearchIcon />
      </IconButton>
    </form>
  );
}

export default OrderSearch;
