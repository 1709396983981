import { useParams, useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import { useFetchGradingById } from "../hooks/gradingHooks";
import CardInformation from "../components/grading/CardInformation";
import GradingNotes from "../components/grading/GradingNotes";
import UpdateGradingScore from "../components/grading/UpdateGradingScore";
import ViewGradingScore from "../components/grading/ViewGradingScore";
import GradingImages from "../components/grading/GradingImages";
import { gradingPanels } from "../config/grading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
      "& > * > .MuiPaper-root": {
        backgroundImage: "linear-gradient( -45deg, #00000091, transparent)",
      },
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
    },
    panel: {
      padding: theme.spacing(2),
    },
  })
);

interface RouterProps {
  gradingId?: string;
}

function UpdateGradingView() {
  const { gradingId } = useParams<RouterProps>();
  const history = useHistory();
  const { isLoading, data: grading } = useFetchGradingById(Number(gradingId));

  const classes = useStyles();

  const handleClose = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push("/gradings");
    }
  };

  if (!gradingId || isLoading) return null;

  if (!grading) {
    return <p>No grading found</p>;
  }

  const permittedPanels =
    gradingPanels[grading.bucket.toLowerCase() as keyof typeof gradingPanels] ||
    [];

  const renderPanel = (name: string, panel: React.ReactNode) => {
    if (!permittedPanels.includes(name)) {
      return null;
    }
    return (
      <Grid item={true} xs={12} md={6} className={classes.panel}>
        {panel}
      </Grid>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullScreen={true}
      aria-labelledby="form-dialog-title"
      className={classes.root}
      disableRestoreFocus={true}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h5" component="h2">
          Card Grading {grading.gradedId} <br />
          <small>
            Slab Type:{" "}
            {grading.slabType === "Normal" ? "Standard" : grading.slabType}
          </small>
        </Typography>
        <IconButton aria-label="close" onClick={() => handleClose()}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <Grid container spacing={2}>
        {renderPanel("information", <CardInformation grading={grading} />)}
        {renderPanel(
          "updateScore",
          <UpdateGradingScore
            gradingId={grading.id}
            centeringGrade={grading.centeringGrade}
            cornersGrade={grading.cornersGrade}
            edgesGrade={grading.edgesGrade}
            surfaceGrade={grading.surfaceGrade}
            finalGrade={grading.finalGrade}
            grade={grading.grade}
          />
        )}
        {renderPanel(
          "viewScore",
          <ViewGradingScore
            centeringGrade={grading.centeringGrade}
            cornersGrade={grading.cornersGrade}
            edgesGrade={grading.edgesGrade}
            surfaceGrade={grading.surfaceGrade}
            finalGrade={grading.finalGrade}
            grade={grading.grade}
          />
        )}
        {renderPanel(
          "notesList",
          <GradingNotes gradingId={grading.id} notes={grading.notes} />
        )}
        {renderPanel(
          "viewImages",
          <GradingImages
            readOnly
            gradingId={grading.id}
            files={grading.files}
          />
        )}
        {renderPanel(
          "updateImages",
          <GradingImages gradingId={grading.id} files={grading.files} />
        )}
      </Grid>
      <DialogActions>
        <Button
          aria-label="close"
          startIcon={<CancelIcon />}
          onClick={() => handleClose()}
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateGradingView;
