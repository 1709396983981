import { useState } from 'react';

import {
  Button,
} from '@material-ui/core';

import CreateModal from './CreateModal';

import { useAddGameInfo } from '../../hooks/catalogHooks';

function AddGameButton(props: { data: any }) {
  const { data } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const { mutateAsync, isMutating } = useAddGameInfo();
  const handleOnSave = async (localData: any) => {
    console.log(localData)
    await mutateAsync(localData);
    setModalOpen(false)
  }

  return (
    <>
      <Button  
        variant="contained"
        size="small"
        color="primary"
        startIcon={""}
        onClick={() => setModalOpen(true)}>
        Add a Game
      </Button>
      <CreateModal
        title={`Add a new game`}
        data={data}
        editableKeys={[
          {
            key: 'game',
            type: 'string',
          },
          {
            key: 'gameCode',
            type: 'string',
          },
          {
            key: 'description',
            type: 'string',
          },
          {
            key: 'reviewed',
            type: 'boolean',
          },
        ]}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={(localData: any) => handleOnSave(localData)}
        isMutating={isMutating}
      />
    </>
  );
}

export default AddGameButton;
