import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { titleCase } from "../../helpers/formattingHelpers";
import AllocateBuckets from "./AllocateBuckets";
import UserActionsMenu from "./UserActionsMenu";

interface UserTableRowProps {
  user: User;
  buckets: Bucket[] | undefined;
}

function UserTableRow(props: UserTableRowProps) {
  const { user, buckets } = props;

  return (
    <TableRow key={user.id}>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{titleCase(user.role)}</TableCell>
      <TableCell align="center">
        {user.enabled ? <CheckIcon /> : <NotInterestedIcon />}
      </TableCell>
      <TableCell>
        <AllocateBuckets availableBuckets={buckets} user={user} />
      </TableCell>
      <TableCell align="right">
        <UserActionsMenu userId={user.id} enabled={user.enabled} />
      </TableCell>
    </TableRow>
  );
}

export default UserTableRow;
