import { useState } from 'react';

import {
  Button,
} from '@material-ui/core';

import CreateModal from './CreateModal';

import { useAddSetInfo } from '../../hooks/catalogHooks';

function AddSetButton(props: { data: { [key: string]: any } }) {
  const { data } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const { mutateAsync, isMutating } = useAddSetInfo();

  const handleOnSave = async (localData: any) => {
    await mutateAsync(localData);
    setModalOpen(false)
  }

  return (
    <>
      <Button 
        variant="contained"
        size="small"
        color="primary"
        startIcon={""}
        onClick={() => setModalOpen(true)}
      >
        Add a Set
      </Button>
      <CreateModal
        title={`Add a new set to ${data['game']}`}
        data={data}
        editableKeys={[
          {
            key: 'setName',
            type: 'string',
          },
          {
            key: 'setCategory',
            type: 'string',
          },
          {
            key: 'releaseDate',
            type: 'string',
          },
          {
            key: 'archived',
            type: 'boolean',
          },
        ]}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={(localData: any) => handleOnSave(localData)}
        isMutating={isMutating }
      />
    </>
  );
}

export default AddSetButton;
