import { useState } from 'react';

import {
  Button,
} from '@material-ui/core';

import CreateModal from './CreateModal';

import { useAddCardInfo } from '../../hooks/catalogHooks';

function AddCardButton(props: { data: { [key: string]: any } }) {
  const { data } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const { mutateAsync, isMutating } = useAddCardInfo();

  const handleOnSave = async (localData: any) => {
    await mutateAsync(localData);
    setModalOpen(false)
  }

  return (
    <>
      <Button 
        variant="contained"
        size="small"
        color="primary"
        startIcon={""}
        onClick={() => setModalOpen(true)}
      >
        Add a Card / Product
      </Button>
      <CreateModal
        title={`Add a new set to ${data['setName']}`}
        data={data}
        editableKeys={[
            {
                key: 'gameCode',
                type: 'string',
              },
              {
                key: 'binderId',
                type: 'string',
              },
              {
                key: 'title',
                type: 'string',
              },
              {
                key: 'cardName',
                type: 'string',
              },
              {
                key: 'rarity',
                type: 'string',
              },
              {
                key: 'number',
                type: 'string',
              },
              {
                key: 'releaseDate',
                type: 'string',
              },
              {
                key: 'setName',
                type: 'string',
              },
              {
                key: 'reviewed',
                type: 'boolean',
              },
              {
                key: 'archived',
                type: 'boolean',
              },
        ]}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={(localData: any) => handleOnSave(localData)}
        isMutating={isMutating }
      />
    </>
  );
}

export default AddCardButton;
