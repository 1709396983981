import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  useUpdateGrading,
  useBulkUpdateGradings,
} from "../../hooks/gradingHooks";
import { useAlert } from "../../hooks/alertHooks";
import { titleCase } from "../../helpers/formattingHelpers";
import ProgressButton from "../generic/ProgressButton";

const useStyles = makeStyles({
  root: {
    width: "12rem",
  },
  select: {
    borderRadius: 4,
    position: "relative",
    textAlign: "center",
    border: "1px solid #ced4da",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  rowButton: {
    marginTop: "1rem",
    maxHeight: "2rem",
  },
});

interface MoveToBucketProps {
  currentBucket?: string;
  grading?: Grading;
  gradingIdList?: number[];
  buckets: Bucket[];
  role?: string;
}

function MoveToBucket(props: MoveToBucketProps) {
  const { currentBucket, grading, gradingIdList, buckets, role } = props;
  const [selectedBucket, setSelectedBucket] = useState<string>("");
  const { mutateAsync: mutateAsyncSingle, isMutating: isMutatingSingle } =
    useUpdateGrading();
  const { mutateAsync: mutateAsyncBulk, isMutating: isMutatingBulk } =
    useBulkUpdateGradings();
  const { addAlert } = useAlert();
  const classes = useStyles();

  useEffect(() => {
    if (grading) {
      setSelectedBucket(grading.bucket);
    }
  }, [grading]);

  const updateSingleGrading = async (newBucket: string) => {
    if (!grading || newBucket === grading.bucket) return;
    try {
      await mutateAsyncSingle({ id: grading.id, bucketUpdate: newBucket });
      addAlert("Bucket updated", "success");
    } catch (error) {
      addAlert("Failed to update bucket", "error");
    }
  };

  const bulkUpdateGradings = async (newBucket: string) => {
    if (!gradingIdList) return;
    try {
      await mutateAsyncBulk({ gradingIds: gradingIdList, bucket: newBucket });
      addAlert("Gradings updated", "success");
    } catch (error) {
      addAlert("Failed to update bucket", "error");
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const newBucket = event.target.value as string;
    setSelectedBucket(newBucket);
    if (grading) {
      updateSingleGrading(newBucket);
    } else {
      bulkUpdateGradings(newBucket);
    }
  };

  if (!buckets || isMutatingSingle || isMutatingBulk)
    return (
      <FormControl className={classes.root}>
        <CircularProgress size={24} />
      </FormControl>
    );

  if (grading?.bucket === "supervisor" && currentBucket !== "supervisor") {
    return <Typography>Referred to supervisor</Typography>;
  }

  if (role !== "admin" && role !== "supervisor") {
    const itemBucket = buckets.find((bucket) => bucket.name === selectedBucket);
    if (!itemBucket?.nextBucket) {
      return null;
    }
    const nextBucketName = itemBucket.nextBucket.name;

    return (
      <ProgressButton
        variant="contained"
        className={classes.rowButton}
        onClick={() => updateSingleGrading(nextBucketName)}
      >{`Move to ${titleCase(nextBucketName)}`}</ProgressButton>
    );
  }

  return (
    <FormControl className={classes.root}>
      <InputLabel id="move-to-bucket-select-label">Move to bucket</InputLabel>
      <Select
        labelId="move-to-bucket-select-label"
        id="move-to-bucket-select"
        className={classes.select}
        value={selectedBucket}
        onChange={handleChange}
      >
        {buckets.map((bucket) => (
          <MenuItem key={bucket.name} value={bucket.name}>
            {titleCase(bucket.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MoveToBucket;
