import { fetchWithToken } from "../services/api";
import {
  buildQueryFromOptionalParams,
  OptionalParamsObject,
} from "../helpers/apiHelpers";
import { API_BASE_URL } from "../config/api";

interface FetchOrdersParams extends OptionalParamsObject {
  offset: number;
  limit: number;
  order: AscendingOrder;
  orderBy?: string;
  bucket?: OptionalString;
}

export const fetchOrders = (params: FetchOrdersParams): Promise<OrderList> => {
  const { order, orderBy, ...urlParams } = params;
  if (orderBy) {
    urlParams["sort"] = `${order === "asc" ? "+" : "-"}${orderBy}`;
  }
  let url = `${API_BASE_URL}/order/gradings?`;
  url += buildQueryFromOptionalParams(urlParams);
  return fetchWithToken({
    method: "GET",
    endpoint: url,
  });
};

export const fetchOrderByBarcode = (barcode: string): Promise<Order> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/order/grading/byBarcode/${barcode}`,
  });
