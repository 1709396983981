import React, { useCallback, useState, createContext, useEffect } from "react";
import Alert from "../components/generic/Alert";

const ALERT_TIMEOUT = 5000; // ms

interface AlertContextProps {
  addAlert: (message: string, severity?: Severity) => void;
  removeAlert: () => void;
}

const defaultContextValue = {
  addAlert: (message: string, severity?: Severity) => {
    return undefined;
  },
  removeAlert: () => {
    return undefined;
  },
};

export const AlertContext =
  createContext<AlertContextProps>(defaultContextValue);

interface AlertProviderProps {
  children: React.ReactChild | React.ReactChildren;
}

function AlertProvider(props: AlertProviderProps) {
  const { children } = props;
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const removeAlert = () => {
    setAlerts((prev) => prev.slice(0, prev.length - 1));
  };

  useEffect(() => {
    if (alerts.length > 0) {
      const timer = setTimeout(removeAlert, ALERT_TIMEOUT);
      return () => clearTimeout(timer);
    }
  });

  const addAlert = useCallback(
    (message: string, severity: Severity = "info") => {
      const newAlert = {
        message,
        severity,
      };
      setAlerts((prev) => [newAlert, ...prev]);
    },
    []
  );

  const activeAlert =
    alerts.length > 0 ? (
      <Alert severity={alerts[0].severity} message={alerts[0].message} />
    ) : null;

  return (
    <AlertContext.Provider value={{ addAlert, removeAlert }}>
      {children}
      {activeAlert}
    </AlertContext.Provider>
  );
}

export default AlertProvider;
