import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ProgressButton from "../generic/ProgressButton";
import { useUpdateGrading } from "../../hooks/gradingHooks";
import { useAlert } from "../../hooks/alertHooks";
import { grades, gradesNumbers } from "../../config/grading";
import { calculateFinalGrade } from "../../helpers/gradingHelpers";
import ScoreIndicator from "../generic/ScoreIndicator";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "1rem"
  },
  title: {
    color: theme.palette.primary.main
  },
  subgradeInput: {
    textAlign: "center",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0
    },
    "& .MuiInputBase-input": {
      textAlign: "center"
    }
  },
  finalScore: {
    textAlign: "center"
  },
  finalGradeLabel: {
    textAlign: "center"
  },
  finalGrade: {
    margin: "2rem auto 0.5rem auto",
    backgroundColor: "#444",
    color: "#fff",
    padding: "0.75rem",
    borderRadius: "1rem",
    maxWidth: "10rem"
  }
}));

interface UpdateGradingScoreProps {
  gradingId: number;
  centeringGrade?: NullableNumber;
  cornersGrade?: NullableNumber;
  edgesGrade?: NullableNumber;
  surfaceGrade?: NullableNumber;
  finalGrade?: NullableNumber;
  grade?: NullableString;
}

function UpdateGradingScore(props: UpdateGradingScoreProps) {
  const {
    gradingId,
    centeringGrade,
    cornersGrade,
    edgesGrade,
    surfaceGrade
  } = props;
  const [centeringGradeValue, setCenteringGradeValue] = useState<number>();
  const [cornersGradeValue, setCornersGradeValue] = useState<number>();
  const [edgesGradeValue, setEdgesGradeValue] = useState<number>();
  const [surfaceGradeValue, setSurfaceGradeValue] = useState<number>();
  const [finalGradeValue, setFinalGradeValue] = useState<number>();
  const [finalGradeDescription, setFinalGradeDescription] = useState<string>();
  const { mutateAsync, isMutating } = useUpdateGrading();
  const { addAlert } = useAlert();
  const classes = useStyles();

  useEffect(() => {
    setCenteringGradeValue(centeringGrade || 0);
    setCornersGradeValue(cornersGrade || 0);
    setEdgesGradeValue(edgesGrade || 0);
    setSurfaceGradeValue(surfaceGrade || 0);
  }, [centeringGrade, cornersGrade, edgesGrade, surfaceGrade]);

  useEffect(() => {
    const finalValue = calculateFinalGrade(
      centeringGradeValue || 0,
      cornersGradeValue || 0,
      edgesGradeValue || 0,
      surfaceGradeValue || 0
    );
    // Used incase we have multiple types of '10' ie GEM mint, gold, pristine etc
    if (Object.keys(gradesNumbers).includes(String(finalValue))) {
      // @ts-ignore
      setFinalGradeValue(gradesNumbers[String(finalValue)]);
    } else {
      setFinalGradeValue(finalValue);
    }
    if (Object.keys(grades).includes(String(finalValue))) {
      // @ts-ignore
      setFinalGradeDescription(grades[String(finalValue)]);
    } else {
      setFinalGradeDescription("");
    }
  }, [
    centeringGradeValue,
    cornersGradeValue,
    edgesGradeValue,
    surfaceGradeValue
  ]);

  const authenticateCard = () => {
    setCenteringGradeValue(0);
    setCornersGradeValue(0);
    setEdgesGradeValue(0);
    setSurfaceGradeValue(0);
  };

  const handleSave = async () => {
    try {
      await mutateAsync({
        id: gradingId,
        centeringGrade: centeringGradeValue,
        cornersGrade: cornersGradeValue,
        edgesGrade: edgesGradeValue,
        surfaceGrade: surfaceGradeValue,
        finalGrade: finalGradeValue,
        grade: finalGradeDescription
      });
      addAlert("Grade updated", "success");
    } catch (error) {
      addAlert("Failed to set grade", "error");
    }
  };

  const canSave = () => {
    if (isMutating) return false;
    if (
      centeringGradeValue !== centeringGrade ||
      cornersGradeValue !== cornersGrade ||
      edgesGradeValue !== edgesGrade ||
      surfaceGradeValue !== surfaceGrade
    ) {
      return true;
    }
    return false;
  };

  const authentic =
    centeringGradeValue === 0 &&
    surfaceGradeValue === 0 &&
    cornersGradeValue === 0 &&
    edgesGradeValue === 0;

  return (
    <Paper className={classes.root}>
      <Button
        variant="contained"
        size="small"
        style={{ float: "right" }}
        color="primary"
        onClick={() => authenticateCard()}
      >
        Authenticate Card (No Grades)
      </Button>
      <Typography variant="h6" className={classes.title}>
        Grading
      </Typography>
      <hr
        style={{
          borderColor: "rgba(224, 224, 224, 1)",
          marginBottom: "1rem"
        }}
      />
      <Grid container>
        <Grid item sm={3}>
          <ScoreIndicator label="Centering Grade" score={centeringGradeValue}>
            <TextField
              className={classes.subgradeInput}
              id="centeringGrade"
              type="number"
              inputProps={{ min: 0, max: 10 }}
              value={centeringGradeValue || 0}
              onChange={event => {
                let value = parseFloat(event.target.value);
                if (value < 0) value = 0;
                if (value > 10) value = 10;
                value = Math.floor(value * 2) / 2;
                setCenteringGradeValue(Number(value));
              }}
            />
          </ScoreIndicator>
        </Grid>
        <Grid item sm={3}>
          <ScoreIndicator label="Corners Grade" score={cornersGradeValue}>
            <TextField
              className={classes.subgradeInput}
              id="cornersGrade"
              type="number"
              inputProps={{ min: 0, max: 10 }}
              value={cornersGradeValue}
              onChange={event => {
                let value = parseFloat(event.target.value);
                if (value < 0) value = 0;
                if (value > 10) value = 10;
                value = Math.floor(value * 2) / 2;
                setCornersGradeValue(Number(value));
              }}
            />
          </ScoreIndicator>
        </Grid>
        <Grid item sm={3}>
          <ScoreIndicator label="Edges Grade" score={edgesGradeValue}>
            <TextField
              className={classes.subgradeInput}
              id="edgesGrade"
              type="number"
              inputProps={{ min: 0, max: 10 }}
              value={edgesGradeValue}
              onChange={event => {
                let value = parseFloat(event.target.value);
                if (value < 0) value = 0;
                if (value > 10) value = 10;
                value = Math.floor(value * 2) / 2;
                setEdgesGradeValue(Number(value));
              }}
            />
          </ScoreIndicator>
        </Grid>
        <Grid item sm={3}>
          <ScoreIndicator label="Surface Grade" score={surfaceGradeValue}>
            <TextField
              className={classes.subgradeInput}
              id="surfaceGrade"
              type="number"
              inputProps={{ min: 0, max: 10 }}
              value={surfaceGradeValue}
              onChange={event => {
                let value = parseFloat(event.target.value);
                if (value < 0) value = 0;
                if (value > 10) value = 10;
                value = Math.floor(value * 2) / 2;
                setSurfaceGradeValue(Number(value));
              }}
            />
          </ScoreIndicator>
        </Grid>
      </Grid>
      <Grid className={classes.finalGrade}>
        <Typography variant="h4" className={classes.finalScore}>
          {authentic ? "A" : finalGradeValue}
        </Typography>
        <Typography className={classes.finalGradeLabel} variant="subtitle2">
          {authentic ? "Authentic" : finalGradeDescription}
        </Typography>
      </Grid>
      <ProgressButton
        onClick={() => handleSave()}
        disabled={!canSave()}
        inProgress={isMutating}
        fullWidth
        variant="contained"
        color="secondary"
      >
        Save
      </ProgressButton>
    </Paper>
  );
}

export default UpdateGradingScore;
