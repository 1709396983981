import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";
import BarcodeIcon from "@material-ui/icons/ViewWeek";
import PrintIcon from "@material-ui/icons/Print";
import GradingActionsMenu from "../grading/GradingActionsMenu";
import MoveToBucket from "../grading/MoveToBucket";
import GradingBarcode from "../grading/GradingBarcode";
import LabelTemplate from "../label/LabelTemplate";
import { currency, getCardName } from "../../helpers/formattingHelpers";

const useStyles = makeStyles((theme) => ({
  row: {
    borderLeft: `2px solid ${theme.palette.primary.light}`,
    borderRight: `2px solid ${theme.palette.primary.light}`,
  },
  rowButton: {
    marginTop: "1rem",
    maxHeight: "2rem",
  },
  itemValue: {
    verticalAlign: "bottom",
  },
  printContent: {
    display: "none",
  },
  actionButton: {
    width: "11rem",
  },
}));

interface OrderGradingsTableRowProps {
  grading: Grading;
  selectedGradings: Grading[];
  currentBucket: string;
  buckets: Bucket[];
  role?: string;
  onRowSelectClick: (
    event: React.MouseEvent<unknown>,
    grading: Grading
  ) => void;
}

function OrderGradingsTableRow(props: OrderGradingsTableRowProps) {
  const {
    grading,
    currentBucket,
    buckets,
    role,
    selectedGradings,
    onRowSelectClick,
  } = props;
  const barcodeRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const isSelected = (id: number) =>
    selectedGradings.findIndex((selectedGrading) => selectedGrading.id === id) >
    -1;

  const handlePrintBarcode = useReactToPrint({
    content: () => barcodeRef.current,
  });

  const handlePrintLabel = useReactToPrint({
    content: () => labelRef.current,
  });

  const getActionButton = (bucket: string) => {
    if (bucket.toLowerCase() === "receiving" || bucket.toLowerCase() === "receiving/research and id") {
      return (
        <>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.rowButton}
            startIcon={<BarcodeIcon />}
            onClick={handlePrintBarcode}
          >
            Print Barcode
          </Button>
          <div className={classes.printContent}>
            <div ref={barcodeRef}>
              <GradingBarcode
                barcode={grading.barcode}
                order={grading.orderBarcode}
                cardName={getCardName(grading)}
                slabType={grading.slabType}
              />
            </div>
          </div>
        </>
      );
    }
    if (
      ["quality checkpoint 1", "label engraving", "quality checkpoint 2", "encapsulating/quality checkpoint 2"].includes(
        grading.bucket.toLowerCase()
      )
    ) {
      return (
        <>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.rowButton}
            startIcon={<PrintIcon />}
            onClick={handlePrintLabel}
          >
            Print Label
          </Button>
          <div className={classes.printContent}>
            <div ref={labelRef}>
              <LabelTemplate grading={grading} />
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <TableRow
      className={classes.row}
      key={grading.id}
      selected={isSelected(grading.id)}
      role="checkbox"
      tabIndex={-1}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected(grading.id)}
          color="primary"
          onClick={(event) => onRowSelectClick(event, grading)}
        />
      </TableCell>
      <TableCell>
        <Typography>{grading.cardName}</Typography>
        <Typography variant="subtitle2">{`${grading.setName} ${
          grading.finish
        } ${grading.cardNumber} - #${grading.barcode
          .substring(grading.barcode.length - 7)
          .replace(/^0+/, "")}`}</Typography>
        <Typography variant="body2">
          Slab Type:{" "}
          {grading.slabType === "Normal" ? "Standard" : grading.slabType}
        </Typography>
      </TableCell>
      <TableCell align="right" className={classes.itemValue}>
        {currency(grading.cardValue)}
      </TableCell>
      <TableCell align="right">
        <Grid container spacing={1} justifyContent="space-between">
          <Button
            component={Link}
            to={`/orders/gradings/${grading.id}`}
            variant="contained"
            size="small"
            color="primary"
            className={classes.rowButton}
            startIcon={<LaunchIcon />}
          >
            Open
          </Button>
          <div className={classes.actionButton}>
            {getActionButton(grading.bucket)}
          </div>
          <MoveToBucket
            grading={grading}
            currentBucket={currentBucket}
            buckets={buckets}
            role={role}
          />
          <GradingActionsMenu grading={grading} />
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default OrderGradingsTableRow;
